import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { useAuth } from './context/AuthContext';
import AdminLogin from './AdminLogin';

export const Layout = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return (
    <div>
      {isAuthenticated ? (
        <>
          <NavMenu />
          <Container tag="main">
            {children}
          </Container>
        </>
      ) : (
        <AdminLogin />
      )}
    </div>
  );
};
