import AddEvent from './pages/AddEvent';
import Members from './pages/Members';
import Events from './pages/Events';
import AdminLogin from './components/AdminLogin';
import AddNewMember from './components/AddNewMemeber';

const AppRoutes = [
  {
    path: '/addevent',
    element: <AddEvent />
  },
  {
    path: '/addmembers',
    element: <Members userSearchParam={false}/>
  },
  {
    path: '/members',
    element: <Members userSearchParam={true} />
  },
  {
    path: '/events',
    element: <Events />
  },
  {
    path: '/login',
    element: <AdminLogin />
  },
  {
    path: '/addnewmember',
    element: <AddNewMember />,
  }
];

export default AppRoutes;
