import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

function AddEvent() {

  const [event, setEvent] = useState({
    Title: '',
    Timestamp: '',
    SU: '',
    Description: '',
    TimetableSlots: [{ Time: '', Text: '' }],
    Price: 0,
    Image: null,
    Address: '', 
  });

  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEvent({
      ...event,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setEvent((prevEvent) => ({
        ...prevEvent,
        Image: e.target.files[0],
      }));
    }
  };

  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 8; hour <= 22; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            slots.push(<option key={time} value={time}>{time}</option>);
        }
    }
    return slots;
  };

  const handleTimetableChange = (e, index) => {
    const { name, value } = e.target;
    const newSlots = [...event.TimetableSlots];
    newSlots[index] = { ...newSlots[index], [name]: value };
    setEvent({
      ...event,
      TimetableSlots: newSlots,
    });
  };

  const addTimeSlot = () => {
    setEvent({
      ...event,
      TimetableSlots: [...event.TimetableSlots, { Time: '', Text: '' }],
    });
  };

  const removeTimeSlot = (index) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      TimetableSlots: prevEvent.TimetableSlots.filter((_, slotIndex) => slotIndex !== index),
    }));
  };

  const validateInputs = () => {
    let errors = {};
    if (!event.Title.trim()) {
      errors.Title = 'Title is required';
    }
    if (!event.Timestamp.trim()) {
      errors.Timestamp = 'Timestamp is required';
    }
    if (!event.Description.trim()) {
      errors.Description = 'Description is required';
    }
    if (event.TimetableSlots.some(slot => !slot.Time.trim() || !slot.Text.trim())) {
      errors.TimetableSlots = 'All timetable slots must have both time and text';
    }
    if (!event.SU.trim()) {
      errors.SU = 'Start Up date is required';
    }
    if (event.Price < 0) {
      errors.Price = 'Price cannot be negative';
    }
    if (!event.Address.trim()) {
      errors.Address = 'Address is required';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInputs()) {
      return;
    }
    setLoading(true);
    setUploadStatus(null); 
  
    const formData = new FormData();
  
    // Append text fields
    Object.keys(event).forEach(key => {
      if (key !== 'Image') { // Exclude the image for now
        formData.append(key, key === 'TimetableSlots' ? JSON.stringify(event[key]) : event[key]);
      }
    });

    // Append the image file if it exists
    if (event.Image) {
      formData.append('Image', event.Image);
    }
    
    try {
      const response = await fetch('https://gourmet-networkin.azurewebsites.net/api/Events', {
        method: 'POST',
        credentials: 'include',
        body: formData, 
      });
  
      if (response.ok) {
        setUploadStatus('success');
      } else {
        const data = await response.json();
        setError(data.message || 'Failed to upload event');
        setUploadStatus('error');
      }
    } catch (error) {
      setError('Failed to upload event');
      setUploadStatus('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container style={{marginBottom: '8rem'}}>
      {uploadStatus === 'success' && <Alert variant="success">Event uploaded successfully!</Alert>}
      {uploadStatus === 'error' && <Alert variant="danger">{error || 'Failed to upload the event.'}</Alert>}
      
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2 className="mt-5">Create Event</h2>
          <Form onSubmit={handleSubmit} className="mt-4">
            <Form.Group className="mb-3" controlId="formImage">
              <div className="mb-3">
                {event.Image ? (
                  <img src={URL.createObjectURL(event.Image)} alt="event image" className="img-fluid mb-3" style={{ maxWidth: '100px' }} />
                ) : (
                  <p>No image</p>
                )}
              </div>
              <Form.Label>Event Image</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event title"
                name="Title"
                value={event.Title}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.Title}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.Title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTimestamp">
              <Form.Label>Timestamp</Form.Label>
              <Form.Control
                type="datetime-local"
                name="Timestamp"
                value={event.Timestamp}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.Timestamp}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.Timestamp}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="Description"
                value={event.Description}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.Description}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.Description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event address"
                name="Address"
                value={event.Address}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.Address}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.Address}
              </Form.Control.Feedback>
            </Form.Group>

            {event.TimetableSlots.map((slot, index) => (
              <Form.Group className="mb-3" key={index} controlId={`formTimetable-${index}`}>
                <Form.Label>Timetable Slot {index + 1}</Form.Label>
                <Row>
                  <Col xs={4}>
                    <Form.Select
                      aria-label={`Timetable slot ${index + 1}`}
                      name="Time"
                      value={slot.Time}
                      onChange={(e) => handleTimetableChange(e, index)}
                      isInvalid={!!validationErrors.TimetableSlots}
                    >
                      <option>Choose time slot</option>
                      {generateTimeSlots()}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Enter details"
                      name="Text"
                      value={slot.Text}
                      onChange={(e) => handleTimetableChange(e, index)}
                      isInvalid={!!validationErrors.TimetableSlots}
                    />
                  </Col>
                  <Col xs={2}>
                    <Button variant="danger" onClick={() => removeTimeSlot(index)}>Remove</Button>
                  </Col>
                </Row>
                <Form.Control.Feedback type="invalid">
                  {validationErrors.TimetableSlots}
                </Form.Control.Feedback>
              </Form.Group>
            ))}

            <Button variant="secondary" className="mb-3" onClick={addTimeSlot}>
              Add Time Slot
            </Button>

            <Form.Group className="mb-3" controlId="formSU">
              <Form.Label>Start Up</Form.Label>
              <Form.Control
                type="date"
                name="SU"
                value={event.SU.split('T')[0]} // We only display the date part for SU
                onChange={(e) =>
                  // When changing the date, we keep the time part at T00:00
                  handleInputChange({
                    target: {
                      name: e.target.name,
                      value: `${e.target.value}T00:00`,
                    },
                  })
                }
                isInvalid={!!validationErrors.SU}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.SU}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter price"
                name="Price"
                value={event.Price}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.Price}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.Price}
              </Form.Control.Feedback>
            </Form.Group>
            
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default AddEvent;
