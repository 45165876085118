import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, ListGroup, Modal, Row } from 'react-bootstrap';

const EditEventModal = ({ show, handleClose, event, handleSave }) => {
  const [editedEvent, setEditedEvent] = useState({
    ...event,
    TimetableSlots: event.TimetableSlots ? JSON.parse(event.TimetableSlots) : [],
    Image: null,
    ImagePath: event.ImagePath 
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedEvent(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = ev => {
        setEditedEvent(prev => ({ ...prev, ImagePath: ev.target.result, Image: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 8; hour <= 22; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            slots.push(<option key={time} value={time}>{time}</option>);
        }
    }
    return slots;
  };

  const handleTimetableChange = (index, field, value) => {
    const updatedSlots = editedEvent.TimetableSlots.map((slot, i) => 
      i === index ? { ...slot, [field]: value } : slot
    );

    setEditedEvent(prev => ({ ...prev, TimetableSlots: updatedSlots }));
  };

  const addTimeSlot = () => {
    setEditedEvent(prev => ({
      ...prev,
      TimetableSlots: [...prev.TimetableSlots, { Time: '', Text: '' }]
    }));
  };

  const removeSlot = index => {
    const filteredSlots = editedEvent.TimetableSlots.filter((_, i) => i !== index);
    setEditedEvent(prev => ({ ...prev, TimetableSlots: filteredSlots }));
  };

  const saveChanges = () => {
    handleSave({
      ...editedEvent,
      TimetableSlots: JSON.stringify(editedEvent.TimetableSlots)
    });
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <Form>
                <Form.Group className="mb-3" controlId="formImage">
                  <div className="mb-3">
                    {editedEvent.ImagePath ? (
                      <img src={editedEvent.ImagePath} alt="Event" className="img-fluid mb-3" style={{ maxWidth: '100px' }} />
                    ) : (
                      <p>No image selected</p>
                    )}
                  </div>
                  <Form.Label>Event Image</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleImageChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formTitle">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter event title"
                    name="Title"
                    value={editedEvent.Title}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formTimestamp">
                  <Form.Label>Timestamp</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="Timestamp"
                    value={editedEvent.Timestamp}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="Description"
                    value={editedEvent.Description}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter event address"
                    name="Address"
                    value={editedEvent.Address}
                    onChange={handleChange}
                  />
                </Form.Group>
                {editedEvent.TimetableSlots.map((slot, index) => (
                  <Form.Group className="mb-3" key={index} controlId={`timetableSlot-${index}`}>
                    <Form.Label>Timetable Slot {index + 1}</Form.Label>
                    <Row>
                      <Col xs={4}>
                        <Form.Select
                          aria-label={`Timetable slot ${index + 1}`}
                          name="Time"
                          value={slot.Time}
                          onChange={(e) => handleTimetableChange(index, 'Time', e.target.value)}
                        >
                          <option>Choose time slot</option>
                          {generateTimeSlots()}
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder="Text"
                          name="Text"
                          value={slot.Text}
                          onChange={(e) => handleTimetableChange(index, 'Text', e.target.value)}
                        />
                      </Col>
                      <Col xs={2}>
                        <Button variant="danger" onClick={() => removeSlot(index)}>Remove</Button>
                      </Col>
                    </Row>
                  </Form.Group>
                ))}
                <Button variant="secondary" onClick={addTimeSlot} className="mb-3">Add Time Slot</Button>
                <Form.Group className="mb-3" controlId="formSU">
                  <Form.Label>Start Up</Form.Label>
                  <Form.Control
                    type="date"
                    name="SU"
                    value={editedEvent.SU.split('T')[0]} 
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPrice">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter price"
                    name="Price"
                    value={editedEvent.Price}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" onClick={saveChanges}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

const Events = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch('https://gourmet-networkin.azurewebsites.net/api/Events', {
      method: 'GET',
      credentials: "include",
    })
    .then(response => {
        if (!response.ok) {
            console.log(response);
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        const eventsData = data.$values;
        if (Array.isArray(eventsData)) {
          const adjustedData = eventsData.map(({ id, title, description, timestamp, su, price, imagePath, timetableSlots, address }) => ({
            id: id,
            Title: title,
            Description: description,
            Price: price,
            SU: su,
            Timestamp: timestamp,
            TimetableSlots: timetableSlots,
            ImagePath: imagePath,
            Address: address
          }));
          setEvents(adjustedData);
        } else {
          throw new Error('Data format is incorrect');
        }
        setIsLoading(false);
    })
    .catch(error => {
        console.error("Failed to fetch events:", error);
        setError(error.toString());
        setIsLoading(false);
    });
}, []);

  const handleEditClick = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSaveEvent = async (editedEvent) => {
    const updatedEvents = events.map(event => 
      event.id === editedEvent.id ? editedEvent : event
    );
    setEvents(updatedEvents);

    const formData = new FormData();
  
    Object.keys(editedEvent).forEach(key => {
      if (key !== 'Image' && key !== 'ImagePath') { 
        formData.append(key, editedEvent[key]);
      }
    });
  
    if (editedEvent.Image instanceof File) { 
      formData.append('Image', editedEvent.Image);
    }
    
    try {
      const url = `https://gourmet-networkin.azurewebsites.net/api/Events/${editedEvent.id}`;
  
      const response = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        body: formData,
      });

      if (response.ok) {
        alert('Event updated successfully!');
      }
    } catch (error) {
     alert('Failed to update event:', error);
    }
  };

  const handleRemoveClick = (event) => {
    setEventToDelete(event);
    setShowDeleteModal(true);
  };

  const handleDeleteEvent = async () => {
    if (eventToDelete) {
      try {
        const url = `https://gourmet-networkin.azurewebsites.net/api/Events/${eventToDelete.id}`;
        const response = await fetch(url, {
          method: 'DELETE',
          credentials: 'include',
        });

        if (response.ok) {
          setEvents(events.filter(event => event.id !== eventToDelete.id));
          setShowDeleteModal(false);
        } 
        alert("Success! \nThe event was successfully deleted")
      } catch (error) {
        alert('Failed to delete event:', error);
      }
    }
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  if (isLoading) return <Container>Loading events...</Container>;
  if (error) return <Container>Error fetching events: {error}</Container>;
  if (events.length === 0) return <Container>No events found.</Container>;

  return (
    <Container style={{ marginBottom: '8rem' }}>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2 className="mt-5 mb-4">Upcoming Events</h2>
          <ListGroup>
            {events.map(event => (
              <ListGroup.Item key={event.id} className="mb-3">
                <Card>
                  <Card.Body>
                    <Row>
                      {event.ImagePath && (
                        <Col md={4} className="mb-3 mb-md-0">
                          <img src={event.ImagePath} alt="Event" className="img-thumbnail" />
                        </Col>
                      )}
                      <Col md={event.ImagePath ? 8 : 12}>
                        <Card.Title className="mb-2">{event.Title}</Card.Title>
                        <Card.Text className="text-muted mb-2">
                          {new Date(event.Timestamp).toLocaleString()}
                        </Card.Text>
                        <Card.Text className="mb-4">
                          {event.Description}
                        </Card.Text>
                        <Card.Text className="text-secondary">
                          <strong>Address:</strong> {event.Address}
                        </Card.Text>
                        <Card.Text className="text-secondary">
                          <strong>Price:</strong> {event.Price > 0 ? `$${event.Price}` : "Free"}
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={6} className="d-flex justify-content-center">
                        <Button variant="primary" style={{width: '90%'}} onClick={() => handleEditClick(event)}>
                          Edit Event
                        </Button>
                      </Col>
                      <Col xs={6} className="d-flex justify-content-center">
                        <Button variant="danger" style={{width: '90%'}} onClick={() => handleRemoveClick(event)}>
                          Delete Event
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
      {showModal && (
        <EditEventModal
          show={showModal}
          handleClose={handleCloseModal}
          event={selectedEvent}
          handleSave={handleSaveEvent}
        />
      )}
      {showDeleteModal && (
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the event "{eventToDelete?.Title}"?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteEvent}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default Events;
