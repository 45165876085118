import React, { useState, useEffect, useRef } from 'react';
import UserCard from '../components/UserCard';
import UserModal from '../components/UserModal';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const PAGE_SIZE = 50;

export default function Members({ userSearchParam }) {
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const loadMoreRef = useRef(null);
  const navigate = useNavigate();

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleAddNewMember = () => {
    navigate('/addnewmember');
  };

  useEffect(() => {
    fetch(`https://gourmet-networkin.azurewebsites.net/api/User/status/${userSearchParam}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .then(data => {
        const users = data.$values || data;
        const adjustedData = users.map(user => ({
          id: user.userDetailsId,
          CompanyName: user.companyName,
          CVR: user.cvr,
          ContactPersonName: user.contactPersonName,
          Email: user.userCredentials?.email,
          PhoneNumber: user.phoneNumber,
          Address: user.address,
          Status: user.status,
          Role: user.role,
          userCredentialsId: user.userCredentialsId,
          RememberMe: user.userCredentials?.rememberMe || false
        }));
        setAllUsers(adjustedData);
        setUsers(adjustedData);
      })
      .catch(error => {
        console.error("Failed to fetch users:", error);
      });
  }, [userSearchParam]);
  
  
  const handleCloseModal = () => setShowModal(false);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    if (!value) {
      setUsers(allUsers.slice(0, PAGE_SIZE));
    } else {
      setUsers(allUsers.filter(user =>
        user.CompanyName.toLowerCase().includes(value.toLowerCase())
      ));
    }
  };

  const handleAccept = async (currentUser) => {
    const updatedUser = {
      ...currentUser,
      Status: true
    };
    const updatedUsers = users.map(user =>
      user.id === currentUser.id ? updatedUser : user
    );

    setUsers(updatedUsers);

    try {
      const url = `https://gourmet-networkin.azurewebsites.net/api/User/approve/${currentUser.id}`;
      const response = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUser), // Ensure that the updated user data is sent in the body
      });

      if (!response.ok) {
        const responseData = await response.json();
        console.error('Failed to approve user:', responseData.message);
        alert('Failed to approve user!');
      } else {
        alert('User approved successfully!');
      }
    } catch (error) {
      console.error('Failed to approve user:', error);
    }
  };

  const handleDelete = async (currentUser) => {
    const updatedUsers = users.filter(user => user.id !== currentUser.id);

    setUsers(updatedUsers);

    try {
      const url = `https://gourmet-networkin.azurewebsites.net/api/User/${currentUser.id}`;
      const response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (response.ok) {
        alert('User deleted successfully!');
      } else {
        console.error('Failed to delete user:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to delete user:', error);
    }
  };

  const loadMore = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && searchTerm === '') {
      setUsers(prevUsers => {
        if (prevUsers.length >= allUsers.length) {
          return prevUsers;
        }
        const moreUsers = allUsers.slice(prevUsers.length, prevUsers.length + PAGE_SIZE);
        return [...prevUsers, ...moreUsers];
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(loadMore, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [searchTerm]);

  const listStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))',
    gap: '20px',
    padding: '20px',
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <input
          type="text"
          placeholder="Search by company name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            fontSize: '1em',
            padding: '10px',
            marginRight: '10px',
            flexGrow: 1,
            boxSizing: 'border-box',
          }}
        />
        <Button onClick={handleAddNewMember} style={{ height: '40px' }}>Add New Member</Button>
      </div>
      <div style={listStyle}>
        {users.filter(user =>
          user.CompanyName.toLowerCase().includes(searchTerm.toLowerCase()) && user.Status === userSearchParam
        ).map(user => (
          <UserCard key={user.id} user={user} userSearchParam={userSearchParam} onUserClick={handleUserClick} onAccept={handleAccept} onDelete={handleDelete} />
        ))}
      </div>
      {showModal && <UserModal user={selectedUser} handleClose={handleCloseModal} show={showModal} />}

      {users.length < allUsers.length && searchTerm === '' && (
        <div ref={loadMoreRef} style={{ height: '20px', margin: '10px 0' }}>
          Loading more users...
        </div>
      )}
    </div>
  );
}
