import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/context/AuthContext';
import './AdminLogin.css';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await fetch('https://gourmet-networkin.azurewebsites.net/admin-api/auth/login', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Full Response Data:", data);
        setMessage(data.message);

        setIsAuthenticated(true);
        navigate('/');
      } else {
        const errorData = await response.json();
        if (username === '' || password === '') {
          setMessage('Login failed: Please fill out both username and password')
        } else {
          setMessage(`Login failed: ${errorData.message || 'Unknown error'}`);
        }
      }
    } catch (error) {
      setMessage(`Login failed: Server error. Please try again`);
    }
  };

  return (
    <div className="login-container">
      <h2>Admin Login</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="login-input"
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="login-input"
      />
      <button onClick={handleLogin} className="login-button">Login</button>
      {message && <p className="login-message">{message}</p>}
    </div>
  );
};

export default AdminLogin;
