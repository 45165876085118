import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function AddNewMember() {
  const [newMember, setNewMember] = useState({
    CompanyName: '',
    CVR: '',
    ContactPersonName: '',
    Email: '',
    Password: '',
    PhoneNumber: '',
    Address: '',
    Status: false,
    Role: 'user',
    RememberMe: false
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMember((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(null);
    setError(null);

    try {
      const response = await fetch('https://gourmet-networkin.azurewebsites.net/api/User/register', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newMember),
      });

      if (response.ok) {
        setSuccess('New member added successfully!');
        navigate('/addmembers');
      } else {
        const responseData = await response.json();
        setError(responseData.message || 'Failed to add new member');
      }
    } catch (error) {
      setError('Failed to add new member');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2>Add New Member</h2>
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formCompanyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter company name"
                name="CompanyName"
                value={newMember.CompanyName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCVR">
              <Form.Label>CVR</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter CVR"
                name="CVR"
                value={newMember.CVR}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formContactPersonName">
              <Form.Label>Contact Person Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter contact person name"
                name="ContactPersonName"
                value={newMember.ContactPersonName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                name="PhoneNumber"
                value={newMember.PhoneNumber}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address"
                name="Address"
                value={newMember.Address}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="Email"
                value={newMember.Email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                name="Password"
                value={newMember.Password}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
