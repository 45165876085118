import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { useAuth } from './components/context/AuthContext';
import AdminLogin from './components/AdminLogin';

// Private route component to protect routes
const PrivateRoute = ({ element: Element, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      element={isAuthenticated ? <Element {...rest} /> : <Navigate to="/login" />}
    />
  );
};

const App = () => (
  <Layout>
    <Routes>
      <Route path="/login" element={<AdminLogin />} />
      {AppRoutes.map((route, index) => {
        const { element, requireAuth, ...rest } = route;
        if (requireAuth) {
          return <PrivateRoute key={index} {...rest} element={element} />;
        }
        return <Route key={index} {...rest} element={element} />;
      })}
    </Routes>
  </Layout>
);

export default App;
