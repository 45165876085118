import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaTimes, FaCheck } from 'react-icons/fa';

const UserCard = ({ user, userSearchParam, onUserClick, onAccept, onDelete }) => {
  const cardStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    transition: 'transform 0.2s ease-in-out',
    boxSizing: 'border-box',
    gap: '10px',
  };

  const hoverStyle = {
    ...cardStyle,
    transform: 'scale(1.03)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  };

  const [style, setStyle] = useState(cardStyle);

  return (
    <div
      style={style}
      onMouseEnter={() => setStyle(hoverStyle)}
      onMouseLeave={() => setStyle(cardStyle)}
      onClick={() => onUserClick(user)}
    >
      <img src={user.ImagePath} alt="Logo" style={{ width: '75px', height: '75px', borderRadius: '50%' }} />
      <div style={{ flex: 1, marginLeft: '15px' }}>
        <h3 style={{ margin: '0 0 10px 0', fontSize: '1.2em', color: '#333' }}>{user.CompanyName}</h3>
        <p style={{ margin: '0', color: '#666' }}>Contact: {user.ContactPersonName}</p>
        <p style={{ margin: '0', color: '#666' }}>Email: {user.Email}</p>
      </div>
      <div className="d-flex flex-column align-items-center" style={{ marginRight: '10px' }}>
        {(!userSearchParam ? (
          <>
            <Button variant="outline-success" className="mb-2" onClick={(e) => { e.stopPropagation(); onAccept(user); }} style={{ width: '40px', height: '40px' }}>
              <FaCheck />
            </Button>
            <Button variant="outline-danger" onClick={(e) => { e.stopPropagation(); onDelete(user); }} style={{ width: '40px', height: '40px' }}>
              <FaTimes />
            </Button>
          </>
        ) : "")}
      </div>
    </div>
  );
};

export default UserCard;
