import React, { useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { FaTimes, FaSave } from 'react-icons/fa';

const parseAddress = (addressString) => {
  const pattern = /^(\D+?)\s+(\d+)(?:\s+(\d{4}))?\s+(\D+?)(?:\s+(\D+))?$/;
  const match = addressString?.match(pattern);

  if (match) {
    return {
      street: match[1],
      number: match[2],
      postalCode: match[3] || '',
      city: match[4],
      country: match[5] || '',
    };
  }
  return null;
};

const UserModal = ({ user: initialUser, handleClose, show }) => {
  const address = initialUser.Address ? parseAddress(initialUser.Address) : {};
  const { street, number, postalCode, city } = address || {};
  const [user, setUser] = useState({
    ...initialUser,
    Street: street && number ? `${street} ${number}` : '',
    PostalCode: postalCode || '',
    City: city || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = ev => {
        setUser(prev => ({ ...prev, ImagePath: ev.target.result, Image: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const saveChanges = async () => {
    try {
      const url = `https://gourmet-networkin.azurewebsites.net/api/User/${user.id}`;
      const formData = new FormData();
      formData.append('UserDetailsId', user.id);
      formData.append('CompanyName', user.CompanyName);
      formData.append('CVR', user.CVR);
      formData.append('ContactPersonName', user.ContactPersonName);
      formData.append('PhoneNumber', user.PhoneNumber);
      formData.append('Address', `${user.Street} ${user.PostalCode} ${user.City}`);
      formData.append('Role', user.Role);
      formData.append('Status', user.Status);
      formData.append('UserCredentialsId', user.userCredentialsId);
      formData.append('UserCredentials.Email', user.Email);
  
//      if (user.Image) {
//        formData.append('Image', user.Image);
//      }
  
      const response = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        body: formData
      });
      console.log(user)
      console.log(formData)
      console.log(response)
      if (!response.ok) {
        const responseData = await response.json();
        console.error('Failed to update user:', responseData.message || responseData);
        alert(`Failed to update user: ${responseData.message || 'Unknown error'}`);
      } else {
        alert('User updated successfully!');
        handleClose();
      }
    } catch (error) {
      console.error('Failed to update user:', error);
      alert(`Failed to update user: ${error.message}`);
    }
  };
  
  

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Logo</Form.Label>
            <div className="mb-3">
              {user.ImagePath ? (
                <img src={user.ImagePath} alt="Logo" className="img-fluid mb-3" style={{ maxWidth: '100px' }} />
              ) : (
                <p>No logo available</p>
              )}
              <Form.Control type="file" onChange={handleImageChange} />
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><strong>Company Name:</strong></Form.Label>
            <Form.Control type="text" name="CompanyName" value={user.CompanyName} onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><strong>CVR:</strong></Form.Label>
            <Form.Control type="text" name="CVR" value={user.CVR} onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><strong>Contact Person:</strong></Form.Label>
            <Form.Control type="text" name="ContactPersonName" value={user.ContactPersonName} onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><strong>Email:</strong></Form.Label>
            <Form.Control type="email" name="Email" value={user.Email} onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><strong>Phone Number:</strong></Form.Label>
            <Form.Control type="text" name="PhoneNumber" value={user.PhoneNumber} onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Street</Form.Label>
            <Form.Control
              type="text"
              name="Street"
              value={user.Street}
              onChange={handleChange}
            />
          </Form.Group>
          <Row>
            <Col md={8} className="mb-3">
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="City"
                  value={user.City}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group>
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="PostalCode"
                  value={user.PostalCode}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={saveChanges}>
          <FaSave /> Save
        </Button>
        <Button variant="danger" onClick={handleClose}>
          <FaTimes /> Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserModal;
